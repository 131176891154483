<template>
    <ul>
        <li>
            <div class="relative bg-white shadow-md px-5 py-8 flex flex-col gap-4">
                <div class="flex flex-row gap-4">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 32" class="w-7 h-7 shrink-0">
                        <g id="clipboard" transform="translate(-3)">
                            <path id="Path_4890" data-name="Path 4890" d="M19,4a3,3,0,0,0-6,0H10V8H22V4Z" fill="none" stroke="#991e66" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            <path id="Path_4891" data-name="Path 4891" d="M25,5h3V31H4V5H7" fill="none" stroke="#991e66" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            <line id="Line_317" data-name="Line 317" x2="15" transform="translate(8.5 14.5)" fill="none" stroke="#991e66" stroke-linecap="round" stroke-width="1"/>
                            <line id="Line_318" data-name="Line 318" x2="15" transform="translate(8.5 19.5)" fill="none" stroke="#991e66" stroke-linecap="round" stroke-width="1"/>
                            <line id="Line_319" data-name="Line 319" x2="15" transform="translate(8.5 24.5)" fill="none" stroke="#991e66" stroke-linecap="round" stroke-width="1"/>
                        </g>
                    </svg>
                    <BaseTypography disable-ellipsis class="text-sm font-medium">Go directly to your patient area to find your test results with your PIN</BaseTypography>
                </div>
                <BaseButton variant="contained" class="capitalize">Your test results</BaseButton>
            </div>
        </li>

        <li>
            <NuxtLinkLocale :locale="switchBlogLocale()" to="/account" class="flex flex-row gap-4 items-center px-6 py-4 hover:bg-black/5">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.5 22.5" class="w-5 h-5 shrink-0">
                    <g id="layout-11" transform="translate(0.25 0.25)">
                        <rect id="Rectangle_192" data-name="Rectangle 192" width="9" height="11" transform="translate(0.5 0.5)" fill="none" stroke="#991e66" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <rect id="Rectangle_193" data-name="Rectangle 193" width="9" height="6" transform="translate(0.5 15.5)" fill="none" stroke="#991e66" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <rect id="Rectangle_194" data-name="Rectangle 194" width="9" height="7" transform="translate(12.5 0.5)" fill="none" stroke="#991e66" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <rect id="Rectangle_195" data-name="Rectangle 195" width="9" height="10" transform="translate(12.5 11.5)" fill="none" stroke="#991e66" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    </g>
                </svg>
                <BaseTypography class="font-semibold">Account Dashboard</BaseTypography>
            </NuxtLinkLocale>
        </li>
        <li>
            <div @click.prevent="logout" class="flex flex-row gap-4 items-center px-6 py-4 cursor-pointer hover:bg-black/5">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 24" class="w-5 h-5 shrink-0">
                    <g id="logout" transform="translate(1 1)">
                        <g id="Group_1445" data-name="Group 1445">
                        <line id="Line_320" data-name="Line 320" x1="12" transform="translate(0 11)" fill="none" stroke="#991e66" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        <path id="Path_4895" data-name="Path 4895" d="M21,16l-4-4,4-4" transform="translate(-17 -1)" fill="none" stroke="#991e66" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        <path id="Path_4896" data-name="Path 4896" d="M5,4V3A2,2,0,0,1,7,1H17a2,2,0,0,1,2,2V21a2,2,0,0,1-2,2H7a2,2,0,0,1-2-2V20" transform="translate(-3 -1)" fill="none" stroke="#991e66" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        </g>
                    </g>
                </svg>
                <BaseTypography class="font-semibold">Log Out</BaseTypography>
            </div>
        </li>
    </ul>
</template>

<style scoped lang="scss">
ul > li {
    @apply border-b;
}
</style>