<template>
  <ul>
    <li>
      <div class="relative bg-white shadow-md px-5 py-8 flex flex-col gap-4">
        <div class="flex flex-row gap-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 26 32"
            class="w-7 h-7 shrink-0"
          >
            <g id="clipboard" transform="translate(-3)">
              <path
                id="Path_4890"
                data-name="Path 4890"
                d="M19,4a3,3,0,0,0-6,0H10V8H22V4Z"
                fill="none"
                stroke="#991e66"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
              <path
                id="Path_4891"
                data-name="Path 4891"
                d="M25,5h3V31H4V5H7"
                fill="none"
                stroke="#991e66"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
              <line
                id="Line_317"
                data-name="Line 317"
                x2="15"
                transform="translate(8.5 14.5)"
                fill="none"
                stroke="#991e66"
                stroke-linecap="round"
                stroke-width="1"
              />
              <line
                id="Line_318"
                data-name="Line 318"
                x2="15"
                transform="translate(8.5 19.5)"
                fill="none"
                stroke="#991e66"
                stroke-linecap="round"
                stroke-width="1"
              />
              <line
                id="Line_319"
                data-name="Line 319"
                x2="15"
                transform="translate(8.5 24.5)"
                fill="none"
                stroke="#991e66"
                stroke-linecap="round"
                stroke-width="1"
              />
            </g>
          </svg>
          <BaseTypography disable-ellipsis class="text-sm font-medium"
            >Go directly to your patient area to find your test results with
            your PIN</BaseTypography
          >
        </div>
        <NuxtLinkLocale :locale="switchBlogLocale()" to="/account/dashboard/results" class="block">
          <BaseButton variant="contained" class="capitalize w-full">
            Your test results
          </BaseButton>
        </NuxtLinkLocale>
      </div>
    </li>

    <li>
      <NuxtLinkLocale :locale="switchBlogLocale()" to="/account/login" class="flex flex-col gap-4 px-6 py-4 hover:bg-slate-100">
        <div class="flex flex-row gap-4 items-center">
          <NuxtImg src="/icons/arrow-right-secondary.svg" />
          <BaseTypography class="font-semibold">Account Login</BaseTypography>
        </div>
        <BaseTypography disable-ellipsis class="text-sm">
          An account is optional and helps you place future home test kit orders
          faster.
          <br /><br />
          For results see our results portal above
        </BaseTypography>
      </NuxtLinkLocale>
    </li>
  </ul>
</template>

<style scoped lang="scss">
ul > li {
  @apply border-b;
}
</style>
