<template>
  <div
    id="accountDrawer"
    class="fixed inset-0 h-[100dvh] z-[999999] isolate overflow-hidden"
  >
    <div
      class="backdrop accountDrawer-transition bg-[#21375699]/80 text-[#253754] absolute inset-0"
      @click="handleCloseSelf"
    ></div>
    <div
      class="relative paper accountDrawer-transition bg-[#F7F7F7] flex flex-col h-full w-96 ml-auto overflow-auto"
    >
      <div
        class="shrink-0 sticky top-0 z-10 bg-inherit py-4 px-6 flex flex-row gap-2 items-center border-b"
      >
        <NuxtImg
          loading="lazy"
          :src="`/icons/${profileDetails ? 'user-logged' : 'profile'}.svg`"
          alt="Patient Login"
          width="27"
          height="25"
          class="shrink-0 w-7 h-7"
        />
        <BaseTypography variant="span" class="font-semibold px-2">{{
          headerTitle
        }}</BaseTypography>

        <button class="ml-auto" @click="handleCloseSelf">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22.828 22.828"
            class="text-secondary w-4 h-4"
          >
            <g id="i-remove" transform="translate(1.414 1.414)">
              <g id="Group_262" data-name="Group 262">
                <line
                  id="Line_74"
                  data-name="Line 74"
                  x1="20"
                  y2="20"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <line
                  id="Line_75"
                  data-name="Line 75"
                  x1="20"
                  y1="20"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
            </g>
          </svg>
        </button>
      </div>

      <div class="shrink-0 flex-grow">
        <AccountDrawerGuestContent v-if="!loggedIn" />
        <AccountDrawerLoggedContent v-else />
      </div>
      <div class="shrink-0 sticky bottom-0 z-10 bg-inherit py-4 px-6 border-t">
        <button
          class="text-secondary font-bold underline"
          @click="handleCloseSelf"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const route = useRoute();

const { profile, loggedIn } = await useUserSession();

const headerTitle = computed(() => {
  switch (true) {
    case !loggedIn:
      return "Your Results";

    case !profile:
      return "My Account";

    case !!profile:
      return `${profile.first_name} ${profile.last_name}`;

    default:
      return "U/N";
  }
});

function handleCloseSelf() {
  const trigger = document.querySelector<HTMLInputElement>(
    "#accountDrawerTrigger"
  );
  if (!trigger) return;

  trigger.checked = false;
}

watch(() => route.fullPath, handleCloseSelf);
</script>

<style scoped lang="scss">
.accountDrawer-transition {
  transition-duration: 500ms;
  transition-property: transform opacity;
}
</style>

<style lang="scss">
body:has(#accountDrawerTrigger:checked) {
  @apply overflow-hidden;
}

body:has(#accountDrawerTrigger:not(:checked)) {
  #accountDrawer {
    @apply pointer-events-none;

    .paper {
      @apply translate-x-full;
    }

    .backdrop {
      @apply opacity-0;
    }
  }
}
</style>
