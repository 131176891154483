<template>
  <!-- Tests sections -->
  <LayoutMegaMenuSection
    :activeSection="props.activeSection"
    anchor-key="tests"
  >
    <LayoutMegaMenuRow>

      <LayoutMegaMenuColumn
        class="bg-stone-white max-w-[17rem] shrink-0"
        sticky
        hide-divider
      >
        <LayoutMegaMenuCategoryAnchor
          v-if="siteSettings.categories.includes('sexual-health')"
          category-anchor="sexual-health"
          :active-category-anchor="testActiveCategoryKey"
          :title="$t('mainmenu.testCategory.sexualHealth.title')"
          :description="$t('mainmenu.testCategory.sexualHealth.introText')"
          :on-activated="handleCategoryAnchorActivated"
        />
        <LayoutMegaMenuCategoryAnchor
          v-if="siteSettings.categories.includes('health-and-wellness')"
          category-anchor="health-and-wellness"
          :active-category-anchor="testActiveCategoryKey"
          :title="$t('mainmenu.testCategory.healthAndWellness.title')"
          :description="$t('mainmenu.testCategory.healthAndWellness.introText')"
          :on-activated="handleCategoryAnchorActivated"
          icon="heart-full.svg"
        />
        <LayoutMegaMenuFooterLink
          class="mt-auto"
        >
          <NuxtLinkLocale 
            :locale="switchBlogLocale()" 
            to="/tests" 
            class="hover:underline" 
            v-on:click.prevent="handleMenuRedirect"
          >
            {{ $t("mainmenu.testCategory.btnBrowseAllTests") }}
          </NuxtLinkLocale>
        </LayoutMegaMenuFooterLink>
      </LayoutMegaMenuColumn>

      <LayoutMegaMenuColumn class="w-screen grow" layout="categories">
        <LayoutMegaMenuCategory
          v-if="siteSettings.categories.includes('sexual-health')"
          :active-key="testActiveCategoryKey"
          anchor-key="sexual-health"
        >
          <template #title>
            <LayoutMegaMenuColumnTitle
              class="flex flex-row gap-2 items-center text-[#213756_!important] font-semibold text-lg"
            >
              {{ $t("mainmenu.testCategory.mostPopularSexualHealthScreen") }}
            </LayoutMegaMenuColumnTitle>
          </template>
          <div
            class="flex flex-col flex-wrap max-h-[25rem] px-6 mt-6 pb-8"
          >
            <LayoutMegaMenuItem
              v-for="(item, index) in testSexualHealthSubMenu"
              :key="index"
              :title="item.title"
              :description="item.description"
              :href="item.href"
            />
          </div>
          <LayoutMegaMenuFooterLink
              class="mt-auto pl-8"
            >
            <NuxtLinkLocale 
              :locale="switchBlogLocale()" 
              to="/tests/sexual-health" 
              class="hover:underline" 
              v-on:click.prevent="handleMenuRedirect"
            >
              {{ $t("mainmenu.testCategory.sexualHealth.introText") }}
            </NuxtLinkLocale>
          </LayoutMegaMenuFooterLink>
        </LayoutMegaMenuCategory>

        <LayoutMegaMenuCategory
          v-if="siteSettings.categories.includes('health-and-wellness')"
          :active-key="testActiveCategoryKey"
          anchor-key="health-and-wellness"
        >
          <template #title>
            <LayoutMegaMenuColumnTitle
              class="flex flex-row gap-2 items-center text-[#213756_!important] font-semibold text-lg"
            >
              {{ $t("mainmenu.testCategory.healthAndWellnessSubjects") }}
            </LayoutMegaMenuColumnTitle>
          </template>
          <div class="grid grid-cols-2 px-6 mt-6 pb-8">
            <div v-for="(submenu, index) in testHealthSubMenu" :key="index" class="space-y-2">
              <LayoutMegaMenuItem
                v-for="(item, index) in submenu"
                :key="index"
                :title="item.title"
                :href="item.href"
              />
            </div>
          </div>
          <LayoutMegaMenuFooterLink
              class="mt-auto pl-8"
            >
            <NuxtLinkLocale 
              :locale="switchBlogLocale()" 
              to="/tests/health-and-wellness" 
              class="hover:underline" 
              v-on:click.prevent="handleMenuRedirect"
            >
              {{ $t("mainmenu.testCategory.healthAndWellness.linkText") }}
            </NuxtLinkLocale>
          </LayoutMegaMenuFooterLink>
        </LayoutMegaMenuCategory>
      </LayoutMegaMenuColumn>

      <LayoutMegaMenuColumn
        class="max-w-[16rem] shrink-0 hidden xl:flex"
        sticky
        hide-divider
      >
        <LayoutMegaMenuContactUs />
      </LayoutMegaMenuColumn>
    </LayoutMegaMenuRow>
  </LayoutMegaMenuSection>
</template>

<script setup lang="ts">
import { useGlobalStore } from "@/stores/global";
import type {
  MegaMenuAnchorKey,
  MegaMenuCategoryAnchorKey,
} from "../index.vue";

interface MegaMenuSection {
  activeSection?: MegaMenuAnchorKey;
}

interface SiteSettings {
  categories: string[];
}

const globalStore = useGlobalStore();
const siteSettings = computed(() => (globalStore.siteSettings ?? []) as SiteSettings);

const props = withDefaults(defineProps<MegaMenuSection>(), {});

const testSexualHealthSubMenu = globalStore.sitetestSexualHealthSubMenu;

const formattedSubCategories = ref({});
if (siteSettings.value.categories.includes('health-and-wellness')) {
  formattedSubCategories.value = globalStore.sitetestHealthSubMenu;
}

const testHealthSubMenu = computed(() => {
  const midpoint = Math.ceil(formattedSubCategories.value.length / 2);
  const result = [formattedSubCategories.value.slice(0, midpoint), formattedSubCategories.value.slice(midpoint)];
  return result;
});

const testActiveCategoryKey = ref<
  MegaMenuCategoryAnchorKey<"tests">
>("sexual-health");
const handleCategoryAnchorActivated = (
  categoryAnchor: MegaMenuCategoryAnchorKey<"tests">
) => {
  testActiveCategoryKey.value = categoryAnchor;
};
</script>
